import { useInView } from 'framer-motion';
import { Ref, useRef } from 'react';
import cn from 'classnames';
// import { useTranslation } from 'react-i18next';

import styles from './AnimatedEntrance.module.scss';

interface IProps<T> {
  children: (ref: Ref<T>, className: string) => any;
  animateInClassName?: string;
  className?: string;
}

const AnimatedEntrance = <T extends HTMLElement>(props: IProps<T>) => {
  const { children, className = styles.animate, animateInClassName = styles.animateIn } = props;

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: '0px 0px -17.5% 0px' });

  return (
    <>
      {children(
        ref,
        cn(className, {
          [animateInClassName]: isInView,
        })
      )}
    </>
  );
};

export default AnimatedEntrance;
