import { useCallback, useContext, useState } from 'react';
import {
  and,
  query,
  where,
  getDocs,
  limit,
  orderBy,
  startAfter,
  DocumentSnapshot,
} from 'firebase/firestore';
import last from 'lodash/last';

import { IFile, IHashtagData, Nullable, TFiles, truthy } from 'types/interfaces';

import consoleLogDev from 'utils/consoleLogDev';
import { filesCollection, hashtagsCollection } from 'utils/firestore';

import GlobalStateContext from 'utils/GlobalStateContext';
import { FILES_PER_FETCH_SIZE } from 'constants/common';

interface IFetchFilesParams {
  hashtag: string;
  folderNames?: string[];
}

type TFetchFiles = (params: IFetchFilesParams) => Promise<void>;

interface IUseFetchFilesReturnType {
  fetchFiles: TFetchFiles;
  fetchNextFiles: () => Promise<void>;
  files: TFiles;
  isLoading: boolean;
  isLoadingMore: boolean;
  clearFiles: () => void;
  hasMore: boolean;
  hashtagData: Nullable<IHashtagData>;
  fetchHashtagData: (hashtag: string) => Promise<IHashtagData>;
}

interface IQueryParams {
  hashtagUid: string;
  folderReference?: Nullable<string>;
  documentSnapshot?: DocumentSnapshot;
}

const fetchHashtagData = async (hashtag: string) => {
  const hashtagQuery = query(hashtagsCollection, where('name', '==', hashtag?.toUpperCase()));
  const foundHashtagSnapshot = await getDocs(hashtagQuery);
  const [foundHashtagDoc] = foundHashtagSnapshot.docs;

  const hashtagData = foundHashtagDoc?.data() as IHashtagData;

  return hashtagData;
};

const fetchFolderUids = async (hashtagUid?: string, folderNames?: string[]) => {
  if (!folderNames) return [];

  const initialPromise: Promise<string[]> = Promise.resolve([]);

  const folderUidsPromise = folderNames.reduce(async (prevFolderUidsPromise, currentFolderName) => {
    const parentFolderUids = await prevFolderUidsPromise;

    const getFolderUid = async () => {
      const parentFolderUid = last(parentFolderUids);

      const filterComponents = [
        where('hashtagUid', '==', hashtagUid),
        where('name', '==', currentFolderName),
        where('folderReference', '==', parentFolderUid || ''),
      ].filter(truthy);

      const folderQuery = query(filesCollection, and(...filterComponents));
      const folderQuerySnapshot = await getDocs(folderQuery);

      const folder = folderQuerySnapshot.docs[0].data() as IFile;

      return folder.uid;
    };

    const folderUid = await getFolderUid();

    return [...parentFolderUids, folderUid];
  }, initialPromise);

  const folderUids = await folderUidsPromise;

  return folderUids;
};

const queryFiles = async (queryParams: IQueryParams) => {
  const { hashtagUid, folderReference, documentSnapshot } = queryParams;

  const filterComponents = [
    where('hashtagUid', '==', hashtagUid),
    where('folderReference', '==', folderReference || ''),
  ].filter(truthy);

  const queryContstraints = [
    orderBy('createdAt', 'desc'),
    documentSnapshot && startAfter(documentSnapshot),
    limit(FILES_PER_FETCH_SIZE),
  ].filter(truthy);

  const filesQuery = query(filesCollection, and(...filterComponents), ...queryContstraints);

  const fileQuerySnapshot = await getDocs(filesQuery);

  const files = fileQuerySnapshot.docs.map(doc => doc.data() as IFile);

  return { files, fileQuerySnapshot };
};

const useFetchFiles = (): IUseFetchFilesReturnType => {
  const [globalState, setGlobalState] = useContext(GlobalStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const clearFiles = useCallback(
    () =>
      setGlobalState({
        files: null,
        folderReference: null,
        fileQuerySnapshot: null,
        hashtagData: null,
        hasMore: false,
      }),
    [setGlobalState]
  );

  const fetchFiles = useCallback(
    async (params: IFetchFilesParams) => {
      const { hashtag, folderNames } = params;

      let foundFiles: TFiles = [];
      let hashtagUid: string = globalState.hashtagData?.uid || '';

      try {
        setIsLoading(true);

        if (globalState.hashtagData?.name !== hashtag || !globalState.hashtagData?.uid) {
          const hashtagData = await fetchHashtagData(hashtag);
          const { uid } = hashtagData;

          hashtagUid = uid;

          setGlobalState({ hashtagData });
        }

        const folderUids = await fetchFolderUids(hashtagUid, folderNames);
        const folderReference = last(folderUids);

        setGlobalState({ folderReference, folderUids });

        const { files, fileQuerySnapshot } = await queryFiles({
          hashtagUid,
          folderReference,
        });

        setGlobalState({
          fileQuerySnapshot,
          hasMore: fileQuerySnapshot.docs.length === FILES_PER_FETCH_SIZE,
        });

        foundFiles = files;
      } catch (error) {
        consoleLogDev(error);
      } finally {
        setIsLoading(false);
        setGlobalState({ files: foundFiles });
      }
    },
    [setGlobalState, globalState.hashtagData?.name, globalState.hashtagData?.uid]
  );

  const fetchNextFiles = useCallback(async () => {
    const { hashtagData, fileQuerySnapshot, files: currentFiles, folderReference } = globalState;

    if (!hashtagData?.uid || !fileQuerySnapshot) return;

    const lastVisibleDocSnapshot = fileQuerySnapshot.docs[fileQuerySnapshot.docs.length - 1];

    setIsLoadingMore(true);

    try {
      const { files: newFiles, fileQuerySnapshot: nextFileQuerySnapshot } = await queryFiles({
        hashtagUid: hashtagData?.uid,
        folderReference,
        documentSnapshot: lastVisibleDocSnapshot,
      });

      setGlobalState({
        fileQuerySnapshot: nextFileQuerySnapshot,
        files: Array.isArray(currentFiles) ? [...currentFiles, ...newFiles] : newFiles,
        hasMore: fileQuerySnapshot.docs.length === FILES_PER_FETCH_SIZE,
      });
    } catch (error) {
      consoleLogDev(error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [globalState, setGlobalState]);

  return {
    fetchFiles,
    fetchNextFiles,
    isLoading,
    isLoadingMore,
    clearFiles,
    files: globalState.files,
    hashtagData: globalState.hashtagData,
    hasMore: globalState.hasMore,
    fetchHashtagData,
  };
};

export default useFetchFiles;
