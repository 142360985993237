import { doc, updateDoc } from 'firebase/firestore';
import { filesize } from 'filesize';
import { getAuth, signOut } from 'firebase/auth';
import { Link, useLocation } from 'react-router-dom';
import { useClickAway, useToggle } from 'react-use';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';

import { ReactComponent as IconArrowLeft } from 'images/icons/arrowDownLeft.svg';
import { ReactComponent as IconCard } from 'images/icons/card.svg';
import { ReactComponent as IconCart } from 'images/icons/cart.svg';
import { ReactComponent as IconChevron } from 'images/icons/chevronRight.svg';
import { ReactComponent as IconDocs } from 'images/icons/twoDocs.svg';
import { ReactComponent as Iconhashare } from 'images/icons/logo6.svg';
import { ReactComponent as IconUser } from 'images/icons/user2.svg';

import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import useGlobalState from 'hooks/useGlobalState';
import useCustomerPortal from 'hooks/useCustomerPortal';

import { db } from 'utils/firestore';
import { showDefaultErrorNotification } from 'utils/notifications';
import consoleLogDev from 'utils/consoleLogDev';

import { PRICING_PAGE_ROUTE } from 'constants/routes';

import styles from './UserMenu.module.scss';

interface IProps {
  className?: string;
}

const schema = yup.object().shape({
  username: yup.string().isValidUsername('').required(''),
});

type TFieldValues = yup.InferType<typeof schema>;

const UserMenu = (props: IProps) => {
  const { t } = useTranslation();

  const { className } = props;

  const location = useLocation();
  const [globalState, setGlobalState] = useGlobalState();
  const { isPortalUrlLoading, navigatePortalUrl } = useCustomerPortal();
  const { isAuthorized, profile, hasActiveOrRenewableSubscription } = globalState;

  const containerRef = useRef<HTMLDivElement>(null);
  const [isProfileOpened, toggleProfileState] = useToggle(false);
  const [isUsernameChangeModalOpened, toggleUsernameModalState] = useToggle(false);
  const [isUsernameChanging, toggleUsernameChangingState] = useToggle(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    toggleProfileState(false);
  }, [location.pathname, toggleProfileState]);

  useEffect(() => {
    if (!isProfileOpened) {
      setTimeout(() => {
        document.documentElement.classList.remove('overflowHidden');
      }, 300);
    }
  }, [isProfileOpened]);

  useClickAway(containerRef, () => {
    toggleProfileState(false);
  });

  const handleProfileLinkClick = () => {
    toggleProfileState();
  };

  const handleLogoutBtnClick = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        toggleProfileState();

        setGlobalState({
          currentUser: null,
          profile: null,
          isAuthorized: false,
          subscription: null,
          subscriptionPlan: null,
          subscriptionPrice: null,
          hasActiveOrRenewableSubscription: false,
        });
      })
      .catch(error => {
        consoleLogDev(error);

        showDefaultErrorNotification();
      });
  };

  const handleUsernameClick = () => {
    toggleUsernameModalState();
  };

  const handleRequestClose = () => {
    toggleUsernameModalState();
    reset();
  };

  const handleManageSubscriptionClick = () => {
    navigatePortalUrl();
  };

  const onUsernameSubmit = async ({ username }: TFieldValues) => {
    if (!profile) return;

    try {
      toggleUsernameChangingState(true);

      const updatedData = {
        name: {
          ...profile.name,
          customUsername: username,
        },
      };

      await updateDoc(doc(db, 'profiles', profile.uid), updatedData);

      setGlobalState({
        profile: {
          ...profile,
          ...updatedData,
        },
      });

      toggleUsernameChangingState(false);
    } catch (error) {
      showDefaultErrorNotification();
    } finally {
      handleRequestClose();
    }
  };

  const hasUsernameError = Boolean(errors.username);

  return (
    <>
      {isAuthorized && profile ? (
        <>
          <div className={cn(styles.overlay, { [styles.overlayOpened]: isProfileOpened })}></div>

          <div className={cn(styles.container, className)} ref={containerRef}>
            <div className={styles.profileLink} onClick={handleProfileLinkClick}>
              <IconUser className={styles.profileLinkIcon} />
              <div className={styles.username}>{profile.name.customUsername}</div>
            </div>

            <div className={cn(styles.profile, { [styles.profileOpened]: isProfileOpened })}>
              <h2 className={styles.profileTitle}>{t('settings')}</h2>

              <div className={cn(styles.section, styles.usernameSection)}>
                <h3 className={styles.sectionTitle}>{t('profileUsername')}</h3>

                <div className={cn(styles.panel)}>
                  <div className={cn(styles.panelItem, 'clickable')} onClick={handleUsernameClick}>
                    <IconUser className={styles.panelIcon} />
                    <div className={styles.panelItemText}>{profile.name.customUsername}</div>

                    <IconChevron className={styles.panelItemChevron}></IconChevron>
                  </div>
                </div>
              </div>

              <div className={cn(styles.section, styles.storageSection)}>
                <h3 className={styles.sectionTitle}>{t('profileUsedStorage')}</h3>

                <div className={styles.panel}>
                  <div className={styles.panelItem}>
                    <div className={styles.planStorage}>
                      <div className={styles.planStorageText}>
                        <IconDocs className={styles.planStorageIcon}></IconDocs>
                        {filesize(profile.usedStorageInMB * 1_000_000)} {t('profileOutOf')}{' '}
                        {filesize(profile.planStorageInMB * 1_000_000)}
                      </div>
                      <div className={styles.planStorageProgressBar}>
                        <div
                          className={styles.planStorageProgressBarProgress}
                          style={{
                            width: `${Math.min(
                              (profile.usedStorageInMB / profile.planStorageInMB) * 100,
                              100
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <Link
                    to={PRICING_PAGE_ROUTE}
                    className={styles.panelItem}
                    onClick={
                      window.location.href.includes(PRICING_PAGE_ROUTE)
                        ? toggleProfileState
                        : undefined
                    }
                  >
                    <div className={styles.panelItemIconContainer}>
                      <IconCart className={cn(styles.panelIcon, styles.panelIconStroke)} />
                    </div>
                    <div className={styles.panelItemText}>{t('buyStorage')}</div>
                  </Link>

                  {hasActiveOrRenewableSubscription && (
                    <div
                      className={cn(styles.panelItem, 'clickable', {
                        [styles.panelItemDisabled]: isPortalUrlLoading,
                      })}
                      onClick={isPortalUrlLoading ? undefined : handleManageSubscriptionClick}
                    >
                      <div className={styles.panelItemIconContainer}>
                        {isPortalUrlLoading ? (
                          <Spinner className={styles.panelIcon}></Spinner>
                        ) : (
                          <IconCard className={cn(styles.panelIcon, styles.panelIconStroke)} />
                        )}
                      </div>
                      <div className={styles.panelItemText}>{t('manageSubscription')}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className={cn(styles.section, styles.accountSection)}>
                <h3 className={styles.sectionTitle}>{t('profileAccount')}</h3>

                <div className={styles.panel}>
                  <div className={cn(styles.panelItem, 'clickable')} onClick={handleLogoutBtnClick}>
                    <div className={styles.panelIconContainer}>
                      <IconArrowLeft className={styles.panelIcon} />
                    </div>
                    <div className={styles.panelText}>{t('profileSignOut')}</div>
                  </div>

                  {/* <div className={cn(styles.panelItem, 'clickable')}>
                    <div className={styles.panelIconContainer}>
                      <IconTrash className={cn(styles.panelIcon, styles.panelIconDanger)} />
                    </div>
                    <div className={styles.panelText}>{t('deleteAccount')}</div>
                  </div> */}
                </div>
              </div>

              <div className={styles.footer}>
                <div className={styles.logoContainer}>
                  <a href="/" className={styles.logoLink}>
                    <Iconhashare className={styles.hasharelogo}></Iconhashare>
                  </a>
                </div>
                <div className={styles.support}>
                  {t('profileSupport')}:{' '}
                  <a href="mailto:help@hash.cloud" className={styles.link}>
                    help@hash.cloud
                  </a>
                </div>
              </div>

              <div className={styles.close} onClick={toggleProfileState}>
                ×
              </div>
            </div>
          </div>

          <Modal
            parentSelector={() => containerRef.current!}
            isOpen={isUsernameChangeModalOpened}
            onRequestClose={handleRequestClose}
            showCloseButton
            className={styles.modal}
            htmlOpenClassName="dummy"
          >
            <h2 className={styles.modalTitle}>{t('usernameChangeModalTitle')}</h2>

            <form onSubmit={handleSubmit(onUsernameSubmit)}>
              <Input
                autoFocus
                placeholder={profile.name.customUsername}
                inputClassName={styles.input}
                hasError={hasUsernameError}
                error={errors.username?.type === 'custom' ? errors.username?.message : undefined}
                {...register('username', { max: 100 })}
              />

              <div className={styles.btnContainer}>
                <Button
                  type="secondary"
                  className={cn(styles.btn, styles.cancelBtn)}
                  onClick={toggleUsernameModalState}
                >
                  {t('cancel')}
                </Button>
                <Button
                  type="primary"
                  className={cn(styles.btn, styles.submitBtn)}
                  htmlType="submit"
                  disabled={hasUsernameError || !watch('username')}
                  isLoading={isUsernameChanging}
                >
                  {t('submit')}
                </Button>
              </div>
            </form>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default UserMenu;
