import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLifecycles, usePrevious } from 'react-use';
import { useNavigate, generatePath } from 'react-router-dom';
import cn from 'classnames';

import Button from 'components/Button';
import CreateNewHashtag from 'components/CreateNewHashtag';
import Footer from 'components/Footer';
import Input from 'components/Input';
import Spinner from 'components/Spinner';

import { ReactComponent as IconAppStore } from 'images/icons/AppStore.svg';
import { ReactComponent as IconArrowSearch } from 'images/icons/arrowSearch.svg';

import useAuth from 'hooks/useAuth';
import useFetchFiles from 'hooks/useFetchFiles';
import useGlobalState from 'hooks/useGlobalState';

import { SEARCH_RESULTS_PAGE_ROUTE } from 'constants/routes';

import styles from './IndexPage.module.scss';

const IndexPage = () => {
  const navigate = useNavigate();
  const [globalState] = useGlobalState();
  const { isAuthLoading, isAuthorized } = globalState;

  const { showAuthModal } = useAuth();
  const { fetchFiles, isLoading, files } = useFetchFiles();

  const [hashtag, setHashtag] = useState('');

  const prevIsDataLoading = usePrevious(isLoading);

  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setHashtag(value.replaceAll('#', ''));
  };

  const handleEnterKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;

    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, []);

  const handleSearchClick = () => {
    if (!hashtag) return;

    fetchFiles({ hashtag });
  };

  useEffect(() => {
    const isJustFinishedLoading = prevIsDataLoading && !isLoading;
    if (isJustFinishedLoading) {
      navigate(generatePath(SEARCH_RESULTS_PAGE_ROUTE, { hashtag }));
    }
  }, [prevIsDataLoading, isLoading, hashtag, navigate, files]);

  useLifecycles(
    () => {
      document.addEventListener('keypress', handleEnterKeyPress);
    },
    () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    }
  );

  const { t } = useTranslation();

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.isGuest]: !isAuthLoading && !isAuthorized,
          [styles.isAuthorized]: !isAuthLoading && !isAuthorized,
        })}
      >
        <div className={styles.buttons}>
          {!isAuthLoading && !isAuthorized && (
            <Button type="dark" className={cn(styles.signInBtn)} onClick={showAuthModal}>
              {t('signIn')}
            </Button>
          )}

          <a
            className={styles.appStoreLink}
            href="https://apps.apple.com/us/app/hashcloud-cloud-storage/id1619279459?itsct=apps_box_link&itscg=30200"
            target="_blank"
            rel="noreferrer"
          >
            <IconAppStore className={styles.appStoreLinkIcon}></IconAppStore>
          </a>
        </div>

        <div className={styles.containerInner}>
          <div className={styles.title}>
            <div className={styles.titleInner}>
              <Trans
                i18nKey="mainPageTitle"
                components={{
                  highlight: <span className={styles.highlight} />,
                }}
              />
            </div>
          </div>

          <div className={styles.centerContainer}>
            <div className={cn(styles.form)}>
              <div className={styles.searchInputGroup}>
                {isLoading ? (
                  <div className={styles.spinnerContainer}>
                    <Spinner className={styles.spinner} />
                  </div>
                ) : (
                  <button
                    ref={searchButtonRef}
                    className={styles.searchButton}
                    onClick={handleSearchClick}
                  >
                    <IconArrowSearch className={styles.iconEye}></IconArrowSearch>
                  </button>
                )}

                <Input
                  autoComplete="off"
                  onChange={handleValueChange}
                  value={hashtag ? '#' + hashtag : ''}
                  className={styles.inputContainer}
                  inputClassName={styles.searchInput}
                  name="hashtag"
                  type="text"
                  placeholder={t('createHashtagInputPlaceholder')}
                  disabled={isLoading}
                />
              </div>

              <div className={cn(styles.formButtons)}>
                <Button
                  type="flat"
                  className={cn(styles.btn, styles.btnShow)}
                  onClick={handleSearchClick}
                  disabled={isLoading}
                  ref={searchButtonRef}
                >
                  {t('mainPageFormBtn1Text')}
                </Button>
                <CreateNewHashtag
                  key={hashtag}
                  defaultHashtag={hashtag}
                  btnClassName={cn(styles.btn, styles.btnCreate)}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          <div className={styles.instructions}>
            <h2 className={styles.instructionsTitle}>{t('instructions')}</h2>

            <h3 className={styles.instructionsSubtitle}>
              <Trans
                i18nKey="mainPageSubtitle"
                values={{
                  webLink: 'https://www.hash.cloud/',
                  appLink:
                    'https://apps.apple.com/us/app/hashcloud-cloud-storage/id1619279459?itsct=apps_box_link&itscg=30200',
                }}
                components={{
                  boldlink: <span className={styles.boldlink} />,
                  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                  a: <a />,
                }}
              />
            </h3>
          </div>
        </div>

        <Footer theme="dark" />
      </div>
    </>
  );
};

export default IndexPage;
