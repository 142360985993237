import { addDoc, updateDoc } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';

import useAuth from 'hooks/useAuth';
import useFetchFiles from 'hooks/useFetchFiles';
import useGlobalState from 'hooks/useGlobalState';

import consoleLogDev from 'utils/consoleLogDev';
import { getFirebaseLikeTimestamp } from 'utils/date';
import { hashtagsCollection } from 'utils/firestore';

import styles from './CreateNewHashtag.module.scss';

interface IProps {
  defaultHashtag?: string;
  btnClassName?: string;
  disabled?: boolean;
}

const schema = yup.object().shape({
  hashtag: yup.string().isValidHashtag('').required(''),
});

type TFieldValues = yup.InferType<typeof schema>;

const CreateNewHashtag = (props: IProps) => {
  const { defaultHashtag, btnClassName, disabled } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showAuthModal } = useAuth();
  const { fetchHashtagData } = useFetchFiles();
  const [isLoading, toggleLoadingState] = useToggle(false);

  const [globalState, _setGlobalState] = useGlobalState();
  const { isAuthorized, currentUser } = globalState;

  const [isModalOpened, toggleModalState] = useToggle(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    ...(defaultHashtag && { defaultValues: { hashtag: defaultHashtag } }),
  });

  const handleClick = () => {
    if (isAuthorized) {
      toggleModalState();
    } else {
      showAuthModal();
    }
  };

  const handleRequestClose = () => {
    toggleModalState();
    reset();
  };

  const onSubmit = async ({ hashtag }: TFieldValues) => {
    try {
      toggleLoadingState();

      const hashtagData = await fetchHashtagData(hashtag);

      if (hashtagData) {
        setError('hashtag', {
          type: 'custom',
          message: t('hashtagAlreadyExists'),
        });
      } else {
        const docRef = await addDoc(hashtagsCollection, {
          createdAt: getFirebaseLikeTimestamp(),
          host: currentUser?.uid,
          name: hashtag.toUpperCase(),
          password: '',
          uid: '',
          id: uuidv4().toUpperCase(),
        });

        await updateDoc(docRef, {
          uid: docRef.id,
        });

        toggleModalState(false);

        if (hashtag === defaultHashtag) {
          window.location.reload();
        } else {
          navigate(`/${hashtag}`);
        }
      }
    } catch (error) {
      consoleLogDev(error);
    } finally {
      toggleLoadingState();
    }
  };

  const hasHashtagError = Boolean(errors.hashtag);

  return (
    <>
      <Button
        className={cn(styles.btn, styles.createHashtagBtn, btnClassName)}
        type="flat"
        onClick={handleClick}
        disabled={disabled}
      >
        <span className={styles.btnIconPlus}></span>
        <span className={styles.btnText}>{t('createHashtag')}</span>
      </Button>

      <Modal isOpen={isModalOpened} onRequestClose={handleRequestClose} showCloseButton>
        <div className={styles.titles}>
          <h2 className={styles.title}>{t('createHashtagModalTitle')}</h2>
        </div>

        <div className={styles.requirementsContainer}>
          <ul className={styles.requirements}>
            <li>{t('createHashtagRequirement1')}</li>
            <li>{t('createHashtagRequirement2')}</li>
            <li>{t('createHashtagRequirement3')}</li>
          </ul>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            autoFocus
            placeholder={t('createHashtagInputPlaceholder')}
            inputClassName={styles.input}
            hasError={hasHashtagError}
            error={errors.hashtag?.type === 'custom' ? errors.hashtag?.message : undefined}
            {...register('hashtag', { max: 100 })}
          />

          <div className={styles.btnContainer}>
            <Button
              type="secondary"
              className={cn(styles.btn, styles.cancelBtn)}
              onClick={handleRequestClose}
            >
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              className={cn(styles.btn, styles.submitBtn)}
              htmlType="submit"
              disabled={hasHashtagError || !watch('hashtag')}
              isLoading={isLoading}
            >
              {t('submit')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateNewHashtag;
