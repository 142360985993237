import { useSetState } from 'react-use';
import { ICustomerPortalSessionParams, Nullable } from 'types/interfaces';
import consoleLogDev from 'utils/consoleLogDev';
import { showDefaultErrorNotification } from 'utils/notifications';
import { getCustomerPortalUrl } from 'utils/stripe';

interface IState {
  portalUrl: Nullable<string>;
  isPortalUrlLoading: boolean;
  portalUrlHasError: boolean;
}

const useCustomerPortal = () => {
  const [state, setLocalState] = useSetState<IState>({
    portalUrl: null,
    isPortalUrlLoading: false,
    portalUrlHasError: false,
  });

  const { portalUrl, isPortalUrlLoading, portalUrlHasError } = state;

  const getPortalUrl = async (params: ICustomerPortalSessionParams = {}) => {
    let newPortalUrl = null;

    try {
      setLocalState({
        isPortalUrlLoading: true,
      });

      newPortalUrl = await getCustomerPortalUrl(params);

      setLocalState({
        portalUrl: newPortalUrl,
        isPortalUrlLoading: false,
      });
    } catch (error) {
      consoleLogDev(error);
      setLocalState({
        portalUrlHasError: true,
      });
    } finally {
      setLocalState({
        isPortalUrlLoading: false,
      });
    }

    return newPortalUrl;
  };

  const navigatePortalUrl = async (params: ICustomerPortalSessionParams = {}) => {
    let newPortalUrl = null;

    try {
      setLocalState({
        isPortalUrlLoading: true,
      });

      newPortalUrl = await getCustomerPortalUrl(params);

      if (newPortalUrl) {
        window.location.assign(newPortalUrl);
      }
    } catch (error) {
      consoleLogDev(error);

      setLocalState({
        portalUrlHasError: true,
        isPortalUrlLoading: false,
      });

      showDefaultErrorNotification();
    }

    return newPortalUrl;
  };

  return { isPortalUrlLoading, portalUrl, getPortalUrl, portalUrlHasError, navigatePortalUrl };
};

export default useCustomerPortal;
