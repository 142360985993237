import { DocumentReference, doc, getDoc, setDoc } from 'firebase/firestore';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { db } from 'utils/firestore';
import { getSubscriptions } from 'utils/stripe';
import consoleLogDev from 'utils/consoleLogDev';

import useGlobalState from 'hooks/useGlobalState';

import { IPrice, IProduct, IProfile, Nullable } from 'types/interfaces';
import { DEFAULT_PLAN_STORAGE_SIZE_IN_MB } from 'constants/common';
import { showErrorNotification, showSuccessfulNotification } from 'utils/notifications';

const useSubscription = () => {
  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();
  const { isSubscriptionLoading } = globalState;

  const updatePlanStorage = useCallback(
    async (profile: Nullable<IProfile>, planStorageInMB: number) => {
      if (!profile) return;

      try {
        const profileData = {
          ...profile,
          planStorageInMB,
        };

        await setDoc(doc(db, 'profiles', profile?.uid), profileData);
        setGlobalState({ profile: profileData });
      } catch (error) {
        consoleLogDev(error);
      }
    },
    [setGlobalState]
  );

  const refreshSubscriptionData = useCallback(
    async (profile: Nullable<IProfile>) => {
      if (isSubscriptionLoading || !profile) return;

      consoleLogDev('Checking the subscription status...');

      try {
        setGlobalState({
          isSubscriptionLoading: true,
        });

        const subscriptions = await getSubscriptions();

        const hasSubscriptions = subscriptions.length > 0;
        const hasActiveOrRenewableSubscription = subscriptions.some(
          subscription => subscription.status !== 'canceled'
        );

        const activeSubscription = subscriptions?.find(
          subscription => subscription.status === 'active' || subscription.status === 'trialing'
        );

        let activeSubscriptionPlan = null;
        let activeSubscriptionPrice = null;

        if (hasSubscriptions) {
          if (activeSubscription) {
            const subsciptionProductDoc = await getDoc(
              activeSubscription.product as unknown as DocumentReference
            );
            const subsciptionPriceDoc = await getDoc(
              activeSubscription.price as unknown as DocumentReference
            );

            activeSubscriptionPlan = {
              ...(subsciptionProductDoc.data() as IProduct),
              productId: subsciptionProductDoc.id,
            };

            activeSubscriptionPrice = {
              ...(subsciptionPriceDoc.data() as IPrice),
              priceId: subsciptionPriceDoc.id,
            };

            const subscriptionPlanStorageInMB = Number(
              activeSubscriptionPlan.metadata.planStorageInMB
            );

            if (
              activeSubscriptionPlan &&
              subscriptionPlanStorageInMB &&
              profile.planStorageInMB !== subscriptionPlanStorageInMB
            ) {
              await updatePlanStorage(profile, subscriptionPlanStorageInMB);

              showSuccessfulNotification(t('subscriptionSuccessMessage'));
            }
          } else if (profile.planStorageInMB !== DEFAULT_PLAN_STORAGE_SIZE_IN_MB) {
            await updatePlanStorage(profile, DEFAULT_PLAN_STORAGE_SIZE_IN_MB);

            showErrorNotification(t('subscriptionInactiveMessage'));
          }
        }

        setGlobalState({
          hasActiveOrRenewableSubscription,
          subscription: activeSubscription,
          subscriptionPlan: activeSubscriptionPlan,
          subscriptionPrice: activeSubscriptionPrice,
        });
      } catch (error) {
        consoleLogDev('Failed checking the subscription status', error);
      } finally {
        setGlobalState({
          isSubscriptionLoading: false,
        });
      }
    },
    [setGlobalState, updatePlanStorage, isSubscriptionLoading, t]
  );

  return {
    hasActiveOrRenewableSubscription: globalState.hasActiveOrRenewableSubscription,
    subscription: globalState.subscription,
    isSubscriptionLoading: globalState.isSubscriptionLoading,
    subscriptionPlan: globalState.subscriptionPlan,
    refreshSubscriptionData,
  };
};

export default useSubscription;
