import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useLayoutEffect,
  useRef,
} from 'react';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { useClickAway, useLongPress, useToggle } from 'react-use';
import { useSelection } from '@viselect/react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import FileRenameModal from 'components/FileRenameModal';

import { ReactComponent as IconEdit } from 'images/icons/editNote.svg';
import { ReactComponent as IconTrash } from 'images/icons/trash.svg';
import { ReactComponent as IconOptions } from 'images/icons/optionsHorizontal.svg';
import { ReactComponent as IconFlag } from 'images/icons/flag.svg';
import { ReactComponent as IconDownload } from 'images/icons/download2.svg';
import { ReactComponent as IconShare } from 'images/icons/share2.svg';

import { concatPaths } from 'utils/urls';
import { db } from 'utils/firestore';
import { showDefaultErrorNotification, showSuccessfulNotification } from 'utils/notifications';
import { transformS3UrlIfNeeded } from 'utils/aws';
import { triggerAhrefDownload } from 'utils/files';

import { IFile, Nullable, truthy } from 'types/interfaces';

import useGlobalState from 'hooks/useGlobalState';
import useS3 from 'hooks/useS3';

import searchPageStyles from 'pages/SearchResultsPage/SearchResultsPage.module.scss';
import styles from './File.module.scss';

interface IProps {
  file: IFile;
  canViewFile: boolean;
  className?: string;
  'data-key'?: string;
  isSelected: boolean;
}

function getContextMenuOverflowClasses(contextMenuEl: HTMLDivElement) {
  const bodyRect = document.body.getBoundingClientRect();
  const contextMenuRect = contextMenuEl.getBoundingClientRect();

  // Check for overflow on the sides of the page
  const overflowClasses = cn({
    [styles.left]: contextMenuRect.left <= bodyRect.left,
    [styles.bottom]: contextMenuRect.bottom > bodyRect.bottom,
    [styles.right]: contextMenuRect.right >= bodyRect.right,
  })
    .split(' ')
    .filter(truthy);

  if (overflowClasses.length) {
    overflowClasses.push(styles.overflow);
  }

  return overflowClasses;
}

const File = (props: IProps) => {
  const { file, canViewFile, className, 'data-key': dataKey, isSelected } = props;

  const gridItemRef = useRef<HTMLDivElement>(null);
  const contextMenuContainerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const selection = useSelection();
  const { triggerFileDownload } = useS3();
  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();
  const { files, profile, currentUser } = globalState;

  const [isContextMenuOpen, toggleContextMenuState] = useToggle(false);
  const [isRenameModalOpen, toggleRenameModalState] = useToggle(false);

  const handleContextMenuOverflow = () => {
    const contextMenuElement = contextMenuContainerRef.current;

    if (!contextMenuElement) return;

    const overflowClasses = getContextMenuOverflowClasses(contextMenuElement);

    contextMenuElement.classList.add(...overflowClasses);
  };

  useLayoutEffect(() => {
    if (isContextMenuOpen) {
      handleContextMenuOverflow();

      const gridItemElement = gridItemRef.current;
      const contextMenuElement = contextMenuContainerRef.current;

      const scrollIntoView = (element: Nullable<HTMLElement>) => {
        if (!element) return;

        smoothScrollIntoView(element, {
          duration: 350,
          block: 'nearest',
          scrollMode: 'if-needed',
        });
      };

      scrollIntoView(contextMenuElement);

      setTimeout(() => {
        scrollIntoView(gridItemElement);
      }, 100);
    }
  }, [isContextMenuOpen]);

  useClickAway(gridItemRef, (event: MouseEvent) => {
    closeContextMenu();

    if (!isMobile) {
      clearSelectionIfNeeded(event);
    }
  });

  const clearSelectionIfNeeded = (event: MouseEvent) => {
    const target = event?.target as HTMLElement;

    const selectedElements = selection?.getSelection();
    const hasSelectedElements = selectedElements && selectedElements?.length > 0;
    const hasModifierKeysPressed = event?.ctrlKey || event?.metaKey;

    const excludedSelectors = ['[class*="FileActionsBar_"]', '.ReactModalPortal'];
    const hasClickedOutsideOtherFiles = !target.closest('[class*="_gridItem_"]');
    const isElementExcluded = excludedSelectors.every(
      excludedSelector => !target.matches(excludedSelector) && !target.closest(excludedSelector)
    );

    if (
      hasSelectedElements &&
      !hasModifierKeysPressed &&
      hasClickedOutsideOtherFiles &&
      isElementExcluded
    ) {
      selection?.clearSelection();
    }
  };

  const openContextMenu = () => {
    toggleContextMenuState(true);
    selection?.clearSelection();
  };

  const closeContextMenu = () => {
    toggleContextMenuState(false);
  };

  useClickAway(gridItemRef, () => {
    closeContextMenu();
  });

  const handleLinkClick = (event: ReactMouseEvent | ReactTouchEvent) => {
    if (isContextMenuOpen) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const openFileInNewTab = () => {
    window.open(transformS3UrlIfNeeded(file.url), '_blank', 'noopener');
  };

  const handleFileClick = (event: ReactMouseEvent | ReactTouchEvent) => {
    if (isContextMenuOpen) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!isMobile) {
      const isDoubleClick = event.detail === 2;

      if (isDoubleClick) {
        openFileInNewTab();
      }
    }
  };

  const onLongPress = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    selection?.select(`.selectable[data-key="${dataKey}"]`);
  };

  const longPressEventOptions = {
    isPreventDefault: false,
    delay: 400,
  };
  const longPressEvent = useLongPress(onLongPress, longPressEventOptions);

  const isFolder = file.type === 'folder';
  const isContextMenuEnabled = canViewFile && (isFolder ? profile?.uid === file.ownerUid : true);

  const iconClassName = isFolder ? styles.folderIcon : styles.fileIcon;

  const toggleSelectedElement = () => {
    const selectedElements = selection?.getSelection();

    const isSelected = selectedElements?.some(
      selectedElement => (selectedElement as HTMLDivElement).dataset?.key === file.uid
    );

    if (isSelected) {
      selection?.deselect(`.selectable[data-key="${dataKey}"]`);
    } else {
      selection?.select(`.selectable[data-key="${dataKey}"]`);
    }
  };

  const handleContextMenu = (event: ReactMouseEvent) => {
    event.preventDefault();

    openContextMenu();
  };

  const handleContextMenuActionClick = (event: ReactMouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isContextMenuOpen) {
      openContextMenu();
    } else {
      closeContextMenu();
    }
  };

  const deleteFileFromFirestore = async () => {
    const docRef = doc(db, 'items', file.uid);

    deleteDoc(docRef);
  };

  const getProfileDataByUid = async () => {
    try {
      const profileRef = doc(db, 'profiles', file.ownerUid);
      const profileDocSnap = await getDoc(profileRef);

      if (profileDocSnap.exists()) {
        return profileDocSnap.data();
      } else {
        throw new Error("Snapshot doesn't exist!");
      }
    } catch (error) {
      return null;
    }
  };

  const updateFileOwnersUsedStorage = async () => {
    const profileData = await getProfileDataByUid();

    if (!profileData) return;

    const updatedData = {
      usedStorageInMB: profileData.usedStorageInMB - file.sizeInMB,
    };

    if (file.ownerUid === profile?.uid) {
      setGlobalState({ profile: { ...profile, ...updatedData } });
    }

    updateDoc(doc(db, 'profiles', file.ownerUid), updatedData);
  };

  const removeFileFromUi = () => {
    setGlobalState({ files: files?.filter(storedFiles => storedFiles.uid !== file.uid) });
  };

  const decorateContextMenuItemClick = (handleClick: () => void) => (event: ReactMouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    toggleContextMenuState(false);

    handleClick();
  };

  const handleReportClick = () => {
    const fullFileName = `${file.name}.${file.type}`;
    const subject = `Report of Inappropriate File -  "${fullFileName}" (ID: ${file.uid})`;
    const today = new Date();

    const formattedDate = today.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const body = `
Dear Support Team,

I am writing to report an issue with a file in the hash.cloud storage. Please find the details below:

File Name: "${fullFileName}"
File ID: ${file.uid}
Reported By: ${profile?.name.customUsername || '[Your Name]'}
User ID/Email: ${profile?.uid || '[Your User ID or Email]'}
Date of Report: ${formattedDate}

Description of the Issue:
[Provide a brief description of the issue with the file. For example, it may contain inappropriate content, malware, or it might be a case of unauthorized sharing.]

Additional Information:
[Include any additional information that might be relevant to the issue. For example, links to the file, screenshots, or steps to reproduce the problem.]

Thank you for your prompt attention to this matter.

Best regards,
${profile?.name.customUsername || '[Your Name]'}
[Your Contact Information]`;

    const url = `mailto:report@hash.cloud?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body.trim())}`;
    window.location.assign(url);
  };

  const handleDeleteClick = async () => {
    try {
      removeFileFromUi();
      // File will be automatically deleted from the AWS via the cloud fuction
      // So we only need to remove it from the firestore
      await deleteFileFromFirestore();
      await updateFileOwnersUsedStorage();

      showSuccessfulNotification(t('fileWasSuccessfullyDeleted'));
    } catch (error) {
      showDefaultErrorNotification();
    }
  };

  const handleEditClick = () => {
    toggleRenameModalState(true);
  };

  const handleDownloadClick = async () => {
    if (currentUser) {
      triggerFileDownload(file);
    } else {
      triggerAhrefDownload(file.url, file.name + file.type);
    }
  };

  const contextMenuItems = [
    {
      id: 'open',
      text: t('fileActionOpen'),
      className: styles.open,
      icon: <IconShare className={styles.contextMenuItemIcon} />,
      onClick: decorateContextMenuItemClick(openFileInNewTab),
      isEnabled: !isFolder,
    },
    {
      id: 'download',
      text: t('fileActionDownload'),
      className: styles.download,
      icon: <IconDownload className={styles.contextMenuItemIcon} />,
      onClick: decorateContextMenuItemClick(handleDownloadClick),
      isEnabled: !isFolder,
    },
    {
      id: 'edit',
      text: t('fileActionEdit'),
      className: styles.edit,
      icon: <IconEdit className={styles.contextMenuItemIcon} />,
      onClick: decorateContextMenuItemClick(handleEditClick),
      isEnabled: profile?.uid === file.ownerUid,
    },
    {
      id: 'report',
      text: t('fileActionReport'),
      className: styles.report,
      icon: <IconFlag className={styles.contextMenuItemIcon} />,
      onClick: decorateContextMenuItemClick(handleReportClick),
      isEnabled: !isFolder,
    },
    {
      id: 'del',
      text: t('fileActionDelete'),
      className: styles.delete,
      icon: <IconTrash className={styles.contextMenuItemIcon} />,
      onClick: decorateContextMenuItemClick(handleDeleteClick),
      isEnabled: !isFolder && profile?.uid === file.ownerUid,
    },
  ].filter(truthy);

  const contextMenuUi = contextMenuItems.length > 0 && isContextMenuOpen && (
    <div
      ref={contextMenuContainerRef}
      className={cn(styles.contextMenuContainer, 'nonSelectable', {
        [styles.contextMenuContainerOpen]: isContextMenuOpen,
      })}
    >
      <div className={cn(styles.contextMenu)}>
        {contextMenuItems
          .filter(contextMenuItem => contextMenuItem.isEnabled)
          .map(contextMenuItem => (
            <div
              onClick={contextMenuItem.onClick}
              key={contextMenuItem.id}
              className={cn(styles.contextMenuItem, contextMenuItem.className)}
            >
              <div className={styles.contextMenuItemText}>{contextMenuItem.text}</div>
              {contextMenuItem.icon}
            </div>
          ))}
      </div>
    </div>
  );

  const fileUi = (
    <div className={cn(styles.file, className)} data-key={dataKey}>
      <div className={iconClassName}>
        {isContextMenuEnabled && (
          <div className={cn(styles.actions, { [styles.actionsMobile]: isMobile })}>
            <div
              className={cn(styles.action, styles.actionConextMenu, 'nonSelectable')}
              onClick={handleContextMenuActionClick}
              title={t('fileMoreActionsTitle')}
            >
              <IconOptions className={styles.actionIcon} />
            </div>
          </div>
        )}

        <div className={styles.fileType}>{isFolder ? '' : canViewFile ? file.type : 'type'}</div>
      </div>

      <div className={cn(styles.fileName, { [styles.fileNameMobile]: isMobile })}>
        {canViewFile ? file.name : 'File name'}
      </div>

      <div className={styles.fileUsername}>{canViewFile ? file.username : 'Username'}</div>

      {contextMenuUi}
    </div>
  );

  const selectedElements = selection?.getSelection();
  const hasSelectedElements = selectedElements && selectedElements.length > 0;

  return (
    <>
      <div
        ref={gridItemRef}
        className={cn(searchPageStyles.gridItem, {
          [styles.gridItemContextMenu]: isContextMenuOpen,
        })}
        onContextMenu={isContextMenuEnabled && !isMobile ? handleContextMenu : undefined}
        {...(isMobile &&
          isContextMenuEnabled && {
            ...longPressEvent,
            onContextMenu: (event: ReactMouseEvent) => event.preventDefault(),
            onClick: hasSelectedElements ? toggleSelectedElement : handleContextMenu,
          })}
      >
        {canViewFile ? (
          <>
            {isFolder ? (
              <Link to={concatPaths(location.pathname, file.name)} onClick={handleLinkClick}>
                {fileUi}
              </Link>
            ) : (
              <div
                className={cn(styles.fileNavigationContainer, { nonSelectable: isSelected })}
                title={`${file.name}`}
                onClick={handleFileClick}
              >
                {fileUi}
              </div>
            )}
          </>
        ) : (
          <>{fileUi}</>
        )}
      </div>

      <div
        className={cn(styles.contextMenuOverlay, {
          [styles.contextMenuOverlayVisible]: isContextMenuOpen,
        })}
      ></div>

      <FileRenameModal
        isOpen={isRenameModalOpen}
        onRequestClose={toggleRenameModalState}
        file={file}
      />
    </>
  );
};

export default File;
