import { and, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';
import trim from 'lodash/trim';

import Modal from 'components/Modal';

import Input from 'components/Input';
import Button from 'components/Button';

import useGlobalState from 'hooks/useGlobalState';

import { filesCollection } from 'utils/firestore';
import { getFirebaseLikeTimestamp } from 'utils/date';
import { showDefaultErrorNotification } from 'utils/notifications';

import { IFile } from 'types/interfaces';

import styles from './CreateNewFolderModal.module.scss';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const schema = yup.object().shape({
  folderName: yup.string().required(),
});

type TFieldValues = yup.InferType<typeof schema>;

const CreateNewFolderModal = (props: IProps) => {
  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();
  const { folderReference, hashtagData, currentUser, profile, files } = globalState;

  const { isOpen, onRequestClose } = props;
  const [isLoading, toggleLoadingState] = useToggle(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm({ criteriaMode: 'all', resolver: yupResolver(schema), reValidateMode: 'onChange' });

  const checkFolderNameAvailability = async (folderName: string, hashtagUid: string) => {
    const foldersQuery = query(
      filesCollection,
      and(
        where('hashtagUid', '==', hashtagUid),
        where('folderReference', '==', folderReference || ''),
        where('name', '==', folderName)
      )
    );
    const foldersSnapshot = await getDocs(foldersQuery);
    const folders = foldersSnapshot.docs.map(folder => folder.data() as IFile);

    return folders.length === 0;
  };

  const handleRequestClose = () => {
    reset();
    onRequestClose?.();
  };

  const onSubmit = async ({ folderName }: TFieldValues) => {
    if (!hashtagData || !currentUser || !folderName) return;

    const newFolderName = trim(folderName);

    try {
      toggleLoadingState();

      const isAvailable = await checkFolderNameAvailability(folderName, hashtagData.uid);

      if (!isAvailable) {
        setError('folderName', {
          type: 'custom',
          message: t('nameIsAlreadyTaken'),
        });

        return;
      }

      const folderDocRef = doc(filesCollection);
      const folderUid = folderDocRef.id;

      const uploadedFileDocData = {
        createdAt: getFirebaseLikeTimestamp(),
        folderReference: folderReference || '',
        hashtagName: hashtagData.name,
        hashtagUid: hashtagData.uid,
        name: newFolderName,
        ownerUid: currentUser.uid,
        sizeInMB: 0,
        type: 'folder',
        url: '',
        username: profile?.name.customUsername,
        uid: folderUid,
      };

      await setDoc(folderDocRef, { ...uploadedFileDocData });

      const prevFiles = cloneDeep(files) || [];
      const newFiles = [uploadedFileDocData, ...prevFiles];

      setGlobalState({ files: newFiles });

      handleRequestClose();
    } catch (error) {
      showDefaultErrorNotification();
    } finally {
      toggleLoadingState();
    }
  };

  const hasError = Boolean(errors.folderName);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        className={styles.modal}
        showCloseButton
      >
        <h2 className={styles.title}>{t('createFolderModalTitle')}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            autoFocus
            placeholder={t('createFolderInputPlaceholder')}
            inputClassName={styles.input}
            hasError={hasError}
            error={errors.folderName?.type === 'custom' ? errors.folderName?.message : undefined}
            {...register('folderName', { max: 100 })}
          />

          <div className={styles.btnContainer}>
            <Button
              type="secondary"
              className={cn(styles.btn, styles.cancelBtn)}
              onClick={handleRequestClose}
            >
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              className={cn(styles.btn, styles.submitBtn)}
              htmlType="submit"
              disabled={hasError || !watch('folderName')}
              isLoading={isLoading}
            >
              {t('submit')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateNewFolderModal;
